.wrapper-dog-details{
    padding:0;
    background:#fbfbfb;
    border-top:1px solid @color-border;
    border-bottom:1px solid @color-border;
    
    .edit-details-bg{
        margin:0;
        padding:6px 10px 0;
        background:#fbfbfb;
        font-size:30px;
        text-align:center;
        color:@color-green;
        border:1px solid @color-border;
        border-top:0px;
        height:55px;
        @media(max-width:400px){
            font-size:26px;
        }
        a{
            color:@color-green;
            &:hover{
                color:#48a539;
            }
        }
    }
    .dog-details{
        margin:0;
        padding:0px;
        width:100%;
        background:#f0f0f0;
        font-size:20px;
        border-left:1px solid @color-border;
        border-bottom:1px solid @color-border;
        height:55px;
        font-family: 'sf_pro_displayregular';
        display:table;
        text-align:left;
        
        
        @media(max-width:1100px){
            font-size:16px;
        }
        @media(max-width:450px){
            padding:0px 0 0 0;
            line-height: 18px;
        }
        
        
        strong{
            margin: 0 1% 0 4%;
            font-family: 'sf_pro_displaymedium';
            margin-right:12px;
            font-weight:normal;
            float: left;
            width: 30%;
            padding: 12px 0 0 0;
            @media(max-width:1300px){
                margin: 0 0% 0 4%;
                width: 32%;
            }
            @media(max-width:767px){
                margin: 0 0px 0 6px;
                padding: 15px 0 0 0;
            }
        }
        .custom-bgpet{
            float: right; 
            width: 60%;
        }
        span{
            color:#494949;
        }
        /*.dog-details-vcenter{
            display:table-cell;
            vertical-align:middle;
            line-height: 22px;
            @media(max-width:450px){
                display:block;
                margin:0;
            }
        }*/
        
    }
    .dog-breed-bg{
        background:#ececec;
    }
    .dog-type-bg{
        background:#e5e5e5;
    }
}
.major-illness-bg{
    margin:0;
    padding:24px 30px 25px;
    width:30%;
    float:left;
    @media(max-width:767px){
        width:50%;
        padding:30px 0px 20px;
    }
    p{
        margin:0;
        padding: 12px 38px 0; 
        font-size:15px;
        line-height: 18px;
    }
    .custom-control-label{
        font-size:18px;
    }
}
.coverage-plan-Fees-bg{
    margin:0;
    padding:32px 0 25px 48px;
    width:40%;
    float:left;
    @media(max-width:991px){
        padding:0px 0 25px 10px;
        width:100%;
    }
    @media(max-width:767px){
        width:100%;
        padding:0px 0 25px 0px;
    }
    
    .btn-section{
        margin:0 6% 0 0;
        width:47%;
        float:left;
        @media(max-width:550px){
            width:100%;
            margin:8px 0 8px 0;
        }
    }
}
.dog-quotation-wrapper{
    margin:0;
    padding:40px 0;
    width:100%;
    background:@color-white;
}
/*marquee{
    margin:0 0 34px 0;
    padding:12px 20px 0;
    width:100%;
    height:56px;
    background:@color-green;
    font-size:21px;
    color:#fbfbfb;    
    font-family: 'sf_pro_displaymedium';
    p{
        margin: 0 15%;
        width: auto;
        display: inline-block;
    }
    
}*/
.offer-tab {
    margin:0 0 34px 0;
    padding:2px 20px 0;
    width:100%;
    height:56px;
    background:@color-green;
    overflow: hidden;
    position: relative;
}
.offer-tab .marquee {
font-size:21px;
color:#fbfbfb;    
font-family: 'sf_pro_displaymedium';
 position: absolute;
 width: 100%;
 height: 100%;
 margin: 0;
 line-height: 50px;
 text-align: center;
 /* Starting position */
     -moz-transform:translateX(100%);
     -webkit-transform:translateX(100%);	
     transform:translateX(100%);
 /* Apply animation to this element */	
     -moz-animation: offer-tab 20s linear infinite;
     -webkit-animation: offer-tab 20s linear infinite;
     animation: offer-tab 20s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes offer-tab {
     0%   { -moz-transform: translateX(100%); }
     100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes offer-tab {
     0%   { -webkit-transform: translateX(100%); }
     100% { -webkit-transform: translateX(-100%); }
}
@keyframes offer-tab {
     0%   { 
     -moz-transform: translateX(100%); /* Firefox bug fix */
     -webkit-transform: translateX(100%); /* Firefox bug fix */
     transform: translateX(100%); 		
     }
     100% { 
     -moz-transform: translateX(-100%); /* Firefox bug fix */
     -webkit-transform: translateX(-100%); /* Firefox bug fix */
     transform: translateX(-100%); 
     }
}

.offer-tab:before{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 28px 0 28px 20px;
    border-color: transparent transparent transparent #ffffff;
    position:absolute;
    top:0;
    left:0;
    content:'';
}
.offer-tab:after{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 28px 20px 28px 0;
    border-color: transparent #ffffff transparent transparent;
    position:absolute;
    top:0;
    right:0;
    content:'';
}

.marquee:hover {
  animation-play-state: paused
}
.quotation-bg{
    margin:0 0 25px 0;
    padding:0;
    width:100%;
    float:left;
    border:1px solid @color-border;
    .ribbon-head-bg{
        margin:0;
        padding:18px 15px 15px 25px;
        width:10%;
        float:left;
        font-size:20px;
        color:@color-red;    
        font-family: 'sf_pro_displaymedium';
        line-height: 24px;
        position: relative;
        
        @media(max-width:1100px){
            width:12%;
        }
        @media(max-width:991px){
            width:15%;
        }
        @media(max-width:767px){
            width:100%;
            padding:15px 15px 20px 25px;
            border-bottom:1px solid @color-border;
        }
        
        i{
            margin:15px 0 0 0;
            font-size:38px;
            display:block;
            
            @media(max-width:767px){
                margin: 0px 0 0 15px;
                font-size: 28px;
                display: inline-block;
            }
            
        }
    }
    .ribbon-head-bg:before{
        width: 0;
        height: 0;
        position:absolute;
        top:19px;
        left:1px;
        content: "\e903";
        font-family: 'icomoon' !important;
        font-size:16px;
        @media(max-width:767px){
            top:22px;
        }
    }
    .quotation-details-bg{
        margin:0;
        padding:0;
        width:72%;
        min-height:145px;
        float:left;
        font-size:17px;
        font-family: 'sf_pro_displaylight';
        line-height: 22px;
        border-left:1px solid @color-border;
        border-right:1px solid @color-border;
        
        @media(max-width:1100px){
            width:70%;
        }
        @media(max-width:991px){
            width:65%;
        }
        @media(max-width:767px){
            width:100%;
            border:0;
        }
        
        .dog-details{
            margin:0;
            padding:10px 20px;
            width:100%;
            font-size:18px;
            height:65px;
            font-family: 'sf_pro_displaymedium';
            display:table;
            text-align:center;
            float: left;
            
            @media(max-width:1100px){
                font-size:16px;
            }
            @media(max-width:767px){
                padding: 10px;
            }
            
            span{
                color:#494949;
            }
            p{
                display:table-cell;
                vertical-align:middle;
                line-height: 22px;
            }
        }
        .dog-details-bg02{
            margin:0;
            padding:26px 30px 0;
            width:100%;
            float:left;
            border-top:1px solid @color-border;
            
            @media(max-width:1100px){
                padding:12px 30px 0;
            }
            @media(max-width:767px){
                padding:12px 15px 10px;
            }
            
            .dog-details02{
                margin:0 7% 0 0;
                padding:0;
                width:auto;
                text-align:left;
                float: left;
                display:inline-block;
                @media(max-width:1300px){
                    margin:0 5% 0 0;
                }
                @media(max-width:1100px){
                    margin:0 5% 10px 0;
                }
                @media(max-width:767px){
                    margin:0 0 10px 0;
                    width:100%;
                }
                
                i{
                    margin:0 10px 0 0;
                    font-size:27px;
                    color:#6ab04c;
                    float:left;
                }
                .icon-major-soft{
                    font-size:25px;
                }
                span{
                    float:left;
                    color:#494949;
                    margin-top: 4px;
                }
                
            }
        }
                
        
    }
    .dog-price-details-bg{
        margin:0;
        padding:10px 20px 0;
        width:18%;
        float:left;
        text-align: center;
        
        @media(max-width:1100px){
            padding:16px 10px 0;
        }
        @media(max-width:991px){
            width:20%;
            padding:16px 20px 0;
        }
        @media(max-width:767px){
            width:100%;
            padding:15px;
            border-top:1px solid @color-border;
        }
        
        .dog-points{
            margin:0;
            padding:0;
            width:100%;
            font-size:14px;
            color:@color-yellow;
             font-family: 'sf_pro_displayregular';
            i{
                margin-right: 5px;
                font-size: 16px;
            }
        }
        .dog-price{
            margin:4px 0;
            padding:0;
            width:100%;
            font-size:30px;
            color:@color-green;
            font-family: 'sf_pro_displaylight';
            @media(max-width:991px){
                font-size:26px;
            }
            @media(max-width:767px){
                font-size:24px;
                line-height:28px;
                margin:15px 0;
            }
        }
    }
    
    .color-green{
        color:#48a539 !important;
    }
    
}