.page-process-wrapper{
    margin:0;
    padding:35px 0;
    width:100%;
    background:#fefefe;
    border-bottom:1px solid @color-border;
    
    @media(max-width:1100px){
        padding:40px 0;
    }
    
    h2{
        margin:0 0 30px 0;
        padding:0;
        width:100%;
        float:left;
         font-family: 'sf_pro_displaymedium';
         font-size:20px;
         letter-spacing:2px;
         
        @media(max-width:1100px){
            margin:0 0 20px 0;
        }
    }
    .page-process-bg{
        margin:0 auto;
        padding:0;
        width:93.5%;
        min-height: 85px;
        position:relative;
        @media(max-width:1100px){
            width:90%;
        }
        @media(max-width:991px){
            width:88%;
        }
        
        .progress-line{
            position:absolute;
            top:27px;
            left:0px;
            right:0px;
            height:1px;
            border-bottom:2px dashed @color-border;
        }
        .progress-complete-line{
            position:absolute;
            top:27px;
            left:0px;
            right:0px;
            height:1px;
            border-bottom:2px dashed @color-orange;
        }
        .progress-content{
            margin:0;
            padding:0;
            width:auto;
            color:#dadee0;
            font-size:18px;
            font-family: 'sf_pro_displaymedium';
            text-align:center;
            display: inline-block;
            position:absolute;
            width: 145px;
            z-index:1;
            a{
                color:#dadee0;
            }
            
            @media(max-width:767px){
                width: 70px;
                line-height: 20px;
                font-size:16px;
            }
            
            .content-icon{
                margin:0 auto 8px;
                padding:0;
                width:55px;
                height:55px;
                font-size:30px;
                line-height:58px;
                background:#dadee0;
                color:@color-white;
                .rounded-corners (@radius: 50%);
                @media(max-width:1100px){
                    margin:0 auto 10px;
                }
            }
        }
        .user-details{
            left:-60px;
            @media(max-width:767px){
                left:-40px;
            }
            @media(max-width:400px){
                left:-30px;
            }
        } 
        .pet-details{
            left:28%;
            @media(max-width:1300px){
                left:27%;
            }
            @media(max-width:991px){
                left:22%;
            }
            @media(max-width:767px){
                left:25%;
            }
            @media(max-width:400px){
                left:20%;
            }
        }
        .summary{
            left:61%;
            @media(max-width:1300px){
                left:60%;
            }
            @media(max-width:991px){
                left:54%;
            }
            @media(max-width:767px){
                left:60%;
            }
            @media(max-width:400px){
                left:52%;
            }
        }
        .payment-gateway{
            right:-60px;
            @media(max-width:767px){
                right:-40px;
            }
            @media(max-width:400px){
                right:-30px;
            }
            
        }
        
    }
    .progress-complete{
        color:@color-orange !important;
        .content-icon{
            color:@color-white !important;
            background:@color-orange  !important;
        }
        a{
            color:@color-orange !important;
        }
    }
}
.user-details-form-wrapper{
    margin:0;
    padding:70px 0;
    width:100%;
    .head-gender{
       font-size:17px;
        font-family: 'sf_pro_displaymedium'; 
        margin-right: 10%;
    }
    
    .custom-checkbox .custom-control-label{
        font-size: 15px;
        color:#6d6d6d;
    }
}
