.topbar {
 background-color:#f7f7f7;
 padding:10px 0;
 border-bottom:1px solid #ededed;
 font-size:15px;
}
.topbar.dark .social a,.topbar.dark-yellow .social a {
 border:0
}
.topbar a,.topbar p {
 color:#3b3d42;
 padding:0
}
.topbar.dark-yellow a,.topbar.dark-yellow p {
 color:#fff
}
.topbar.dark a,.topbar.dark p {
 color:#8b8d92
}
.header {
 padding:20px 0 0;
 background-color:#ffffff;
}
@media(max-width:991px){
    .header {
        padding:4px 0 0px;
        position: relative;
        z-index: 999;
    }
    .navbar-collapse .navbar-cell{
        border-top: 1px solid #e6e6e6;
    }
}
.navbar-default {
 margin:0;
 background:0 0;
 border:0
}
.navbar-default .nav>li>a {
 color: #766142;
 border:0!important;
 font-weight:500!important;
 margin:0!important;
 font-size:14px;
 padding: 13px 40px 11px 40px;
letter-spacing: 2px;
}
@media(max-width:1100px){
    .navbar-default .nav>li>a {
        padding: 13px 20px 11px 20px;
    }
}
.navbar-nav .has-submenu .dropdown-menu li a {
 padding: 6px 20px;
font-size: 15px !important;
float: left;
width: 100%;
}
.navbar-nav .has-submenu .dropdown-menu li a:hover {
  color:#000000;
}
.navbar-default .dropdown-menu {
 box-shadow:none
}
.navbar-default .dropdown-menu li a {
 color:#766142;
 font-size:14px
}
.navbar-default .fa-angle-right {
 position:absolute;
 right:30px
}
.navbar-brand img {
 margin:0!important
}
.social a {
 border-left:1px solid #ddd;
 font-size:14px;
 padding-left:14px;
 padding-right:12px;
 color:#94969b;
 margin:0 !important;
 display:inline-block
}
@media(max-width:767px){
    .social {
    text-align:center;
    }
    .social a {
     padding-left:10px;
     padding-right:10px;
     margin-top:10px !important;
    }
}
.social a:hover {
 color:#494949;
}
.social a:last-child {
 border-right:0;
 padding-right:0px
}
.social a:first-child {
 border:0;
}
.navbar-default .navbar-nav .open .dropdown-menu>li>a,.navbar-default .navbar-nav>.open>a,.navbar-default .navbar-nav>.open>a:focus,.navbar-default .navbar-nav>.open>a:hover {
 color:#fff!important
}

.navbar-table {
    width: 100%;
}
.navbar-toggle {
    background: #ffffff;
    border: 0;
    padding: 4px 10px;
    cursor: pointer;
}
.topbar .callus{
    margin: 0 6px 0 0;
    padding: 0 8px 0 0;
    border-right: 1px solid #dddddd;
    font-size: 14px;
    strong{
        font-size: 15px;
    }
    @media (max-width:560px) {
        border-right: 0;
        display:block;
    }
}

@media (min-width:992px) {
     .navbar-header {
      float:left
     }
}
@media screen and (min-width:992px) {
     .navbar-table {
      display:inherit;
      width:100%
     }
     .navbar-cell {
      display:table-cell;
      vertical-align:middle
     }
     .navbar-cell.stretch {
      width:100%
     }
     .navbar-cell.tight {
      width:auto;
      white-space:nowrap
     }
     .navbar-cell.full-height {
      height:100%
     }
}
.navbar {
 position:relative;
 border-bottom:0;
}
@media (max-width:991px) {
     .navbar-header {
      display:table;
      width:100%
     }
     .navbar-header>* {
      display:table-cell;
      vertical-align:middle
     }
     .navbar>.container .navbar-brand,.navbar>.container-fluid .navbar-brand {
      padding:.8em 0
     }
}
.navbar-collapse {
 overflow-x:visible;
 -webkit-overflow-scrolling:touch
}
.navbar-collapse.in {
 overflow-y:auto
}
.navbar-fixed-bottom .navbar-collapse,.navbar-fixed-top .navbar-collapse {
 max-height:340px
}
@media (max-device-width:480px) and (orientation:landscape) {
     .navbar-fixed-bottom .navbar-collapse,.navbar-fixed-top .navbar-collapse {
      max-height:200px
     }
}
.navbar-static-top {
 z-index:200
}
.navbar-fixed-bottom,.navbar-fixed-top {
 position:fixed;
 right:0;
 left:0;
 z-index:400
}
.navbar-fixed-top {
 top:0
}
@media (min-width:992px) {
     .navbar-collapse {
      width:100%;
      box-shadow:none
     }
     .navbar-collapse>* {
      display:table-cell;
      vertical-align:middle
     }
     .navbar-collapse.collapse {
      display:table;
      visibility:visible!important;
      height:auto!important;
      overflow:visible!important
     }
     .navbar-collapse.in {
      overflow-y:visible
     }
     .navbar-right {
      float:right
     }
     .navbar-left {
      float:left
     }
}
.navbar-brand {
 font-size:18px
}
.navbar-brand:focus,.navbar-brand:hover {
 text-decoration:none
}
.navbar-brand>img {
 display:block
}
.navbar-toggle {
 position:relative;
 float:right;
 padding:0 .3em;
 background-color:transparent
}
.navbar-toggle:focus {
 outline:0
}
.navbar-nav {
 margin:0;
 display: inline-block;
 
    @media (max-width:991px) {
        width:100%;   
        .dropdown-menu {
            border: 0;
            margin-bottom: 10px !important;
            overflow: hidden;
        }
    }
}
@media (max-width:991px) {
     .navbar-nav .open .dropdown-menu {
      position:static;
      float:none;
      width:auto;
      margin-top:0;
      background-color:transparent
     }
     .navbar-nav .open .dropdown-menu>li>a:focus,.navbar-nav .open .dropdown-menu>li>a:hover {
      background-image:none
     }
     .navbar-nav .caret {
      position:absolute;
      top:50%;
      right:.8em;
      -webkit-transform:translate(0,-50%);
      -ms-transform:translate(0,-50%);
      -o-transform:translate(0,-50%);
      transform:translate(0,-50%)
     }
     .navbar-nav li a {
      padding:.8em!important;
      border-bottom:1px solid;
      margin-top:-1px
     }
     .navbar-nav a.last {
      border-bottom:0
     }
     .navbar-form .form-group:last-child {
      margin-bottom:0
     }
}
@media (min-width:992px) {
     .navbar-toggle {
      display:none
     }
     .navbar-nav>li {
      display:inline-block!important
     }
     .navbar-nav>li>a {
      padding-top:13px !important;
      padding-bottom:11px !important
     }
     .navbar-nav .has-submenu {
      position:relative
     }
     .navbar-nav .has-submenu:hover>ul {
      visibility:visible;
      opacity:1;
      top:100%
     }
     .navbar-nav .has-submenu ul {
      -webkit-transition:visibility .35s ease-in-out,opacity .35s ease-in-out,top .35s ease-in-out,bottom .35s ease-in-out,left .35s ease-in-out,right .35s ease-in-out;
      -moz-transition:visibility .35s ease-in-out,opacity .35s ease-in-out,top .35s ease-in-out,bottom .35s ease-in-out,left .35s ease-in-out,right .35s ease-in-out;
      -o-transition:visibility .35s ease-in-out,opacity .35s ease-in-out,top .35s ease-in-out,bottom .35s ease-in-out,left .35s ease-in-out,right .35s ease-in-out;
      transition:visibility .35s ease-in-out,opacity .35s ease-in-out,top .35s ease-in-out,bottom .35s ease-in-out,left .35s ease-in-out,right .35s ease-in-out
     }
     .navbar-nav .has-submenu .dropdown-menu {
      min-width:240px;
      padding:0;
      -webkit-border-radius:0;
      -moz-border-radius:0;
      border-radius:0;
      padding: 6px 0;
     }
     .navbar-nav .has-submenu .dropdown-menu li a {
        padding: 6px 20px;
     }
     .navbar-nav .has-submenu>ul {
      position:absolute;
      top:130%;
      visibility:hidden;
      display:block;
      opacity:0;
      left:0
     }
     .navbar-nav .has-submenu>ul.start-right {
      right:0;
      left:auto
     }
     .navbar-nav .has-submenu>ul li {
      position:relative
      padding: 2px 0;
     }
     .navbar-nav .has-submenu>ul li:hover>ul {
      visibility:visible;
      opacity:1;
      left:100%
     }
     .navbar-nav .has-submenu>ul li:hover>ul.show-right {
      right:100%
     }
     .navbar-nav .has-submenu>ul li ul {
      position:absolute;
      top:0;
      visibility:hidden;
      display:block;
      opacity:0;
      left:130%
     }
     .navbar-nav .has-submenu>ul li ul.show-right {
      right:130%;
      left:auto
     }
     .navbar-fixed-bottom .navbar-nav .has-submenu:hover>ul {
      bottom:100%;
      top:auto
     }
     .navbar-fixed-bottom .navbar-nav .has-submenu>ul {
      top:auto;
      bottom:130%
     }
     .navbar-fixed-bottom .navbar-nav .has-submenu>ul li ul {
      top:auto;
      bottom:0
     }
     .navbar-form {
      width:auto;
      border:0
     }
}
.nav-justified>.dropdown .dropdown-menu,.nav-tabs.nav-justified>.dropdown .dropdown-menu {
 top:auto;
 left:auto
}
.navbar-form label {
 padding-top:0
}
@media (min-width:768px) {
     .navbar-form .form-group {
      display:inline-block;
      margin-bottom:0;
      vertical-align:middle
     }
     .navbar-form .form-control {
      display:inline-block;
      width:auto;
      vertical-align:middle
     }
     .navbar-form .form-control-static {
      display:inline-block
     }
     .navbar-form .input-group {
      display:inline-table;
      vertical-align:middle
     }
     .navbar-form .control-label {
      vertical-align:middle
     }
     .navbar-form .checkbox,.navbar-form .radio {
      display:inline-block;
      vertical-align:middle
     }
     .navbar-form .checkbox input[type=checkbox],.navbar-form .radio input[type=radio] {
      position:relative
     }
}
.nav>li,.nav>li>a {
 display:block;
 position:relative
}
.navbar-default,.navbar-default .navbar-collapse,.navbar-default .navbar-form {
 border-color:#e6e6e6
}
.navbar-nav .dropdown-menu {
 margin-top:0;
 margin-bottom:0!important;
 padding:0
}
.navbar-default .navbar-brand {
 color:#4d4d4d
}
.navbar-default .navbar-brand:focus,.navbar-default .navbar-brand:hover {
 color:#745ec5;
 background-color:transparent
}
.navbar-default .navbar-text {
 color:#999
}

.navbar-default .navbar-nav>.active>a,.navbar-default .navbar-nav>.active>a:focus,.navbar-default .navbar-nav>.active>a:hover,.navbar-default .navbar-nav>li:focus>a,.navbar-default .navbar-nav>li:hover>a {
 color:#000000;
 background-color:transparent;
}
.navbar-default .navbar-nav>.disabled>a,.navbar-default .navbar-nav>.disabled>a:focus,.navbar-default .navbar-nav>.disabled>a:hover {
 color:gray;
 background-color:transparent
}
.navbar-default .navbar-nav>.open>a,.navbar-default .navbar-nav>.open>a:focus,.navbar-default .navbar-nav>.open>a:hover {
 background-color:#e6e6e6;
 color:#666
}
@media (max-width:991px) {
     .navbar-default .navbar-nav li a,.navbar-default .navbar-nav li a:hover {
      border-color:#e6e6e6
     }
     .navbar-default .navbar-nav .open .dropdown-menu>li>a {
      color:gray
     }
     .navbar-default .navbar-nav .open .dropdown-menu>.active>a,.navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,.navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover,.navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
      color:#666;
      background-color:#e6e6e6
     }
     .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,.navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
      color:gray;
      background-color:transparent
     }
}
.navbar-default .navbar-link {
 color:gray
}
.navbar-default .navbar-link:hover {
 color:#666
}
.navbar-default .btn-link {
 color:gray
}
.navbar-default .btn-link:focus,.navbar-default .btn-link:hover {
 color:#666
}
.navbar-default .btn-link[disabled]:focus,.navbar-default .btn-link[disabled]:hover,fieldset[disabled] .navbar-default .btn-link:focus,fieldset[disabled] .navbar-default .btn-link:hover {
 color:gray
}
.navbar-inverse {
 background-color:#2b2b2b;
 border-color:#454545
}
.navbar-inverse .navbar-brand {
 color:#c4c4c4
}
.navbar-inverse .navbar-brand:focus,.navbar-inverse .navbar-brand:hover {
 color:#ddd;
 background-color:transparent
}
.navbar-inverse .navbar-text {
 color:#787878
}
.navbar-inverse .navbar-nav>li>a {
 color:#919191
}
.navbar-inverse .navbar-nav>.active>a,.navbar-inverse .navbar-nav>.active>a:focus,.navbar-inverse .navbar-nav>.active>a:hover,.navbar-inverse .navbar-nav>li:focus>a,.navbar-inverse .navbar-nav>li:hover>a {
 color:#ababab;
 background-color:#1e1e1e
}
.navbar-inverse .navbar-nav>.disabled>a,.navbar-inverse .navbar-nav>.disabled>a:focus,.navbar-inverse .navbar-nav>.disabled>a:hover {
 color:#919191;
 background-color:transparent
}
.navbar-inverse .navbar-collapse,.navbar-inverse .navbar-form {
 border-color:#191919
}
.navbar-inverse .navbar-nav>.open>a,.navbar-inverse .navbar-nav>.open>a:focus,.navbar-inverse .navbar-nav>.open>a:hover {
 background-color:#1e1e1e;
 color:#ababab
}
@media (max-width:991px) {
     .navbar-inverse .navbar-nav li a {
      border-color:#353535
     }
     .navbar-inverse .navbar-nav li a:hover {
      border-color:#1e1e1e
     }
     .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
      border-color:#454545
     }
     .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
      background-color:#454545
     }
     .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
      color:#919191
     }
     .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,.navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover,.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
      color:#ababab;
      background-color:#1e1e1e
     }
     .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,.navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
      color:#919191;
      background-color:transparent
     }
}
.navbar-inverse .navbar-link {
 color:#919191
}
.navbar-inverse .navbar-link:hover {
 color:#ababab
}
.navbar-inverse .btn-link {
 color:#919191
}
.navbar-inverse .btn-link:focus,.navbar-inverse .btn-link:hover {
 color:#ababab
}
.navbar-inverse .btn-link[disabled]:focus,.navbar-inverse .btn-link[disabled]:hover,fieldset[disabled] .navbar-inverse .btn-link:focus,fieldset[disabled] .navbar-inverse .btn-link:hover {
 color:#919191
}
.nav {
 padding-left:0
}
.nav>li>a {
 padding:1em
}
.nav>li>a:focus,.nav>li>a:hover {
 text-decoration:none
}
.nav>li.disabled>a {
 color:#ccc
}
.nav>li.disabled>a:focus,.nav>li.disabled>a:hover {
 color:#ccc;
 text-decoration:none;
 background-color:transparent;
 cursor:not-allowed
}
.nav-tabs {
 border-bottom:1px solid #e6e6e6
}
.nav-tabs>li {
 float:left;
 margin-bottom:-1px
}
.nav-tabs>li>a {
 margin-right:-1px;
 line-height:1;
 border:1px solid #e6e6e6;
 color:#999
}
.nav-tabs>li>a:hover {
 border-top-color:#2ecc71;
 color:#2ecc71
}
.nav-tabs>li.active>a,.nav-tabs>li.active>a:focus,.nav-tabs>li.active>a:hover {
 border-top-color:#2ecc71;
 border-bottom-color:#fff;
 color:#2ecc71;
 cursor:default
}
.nav-tabs.nav-justified {
 width:100%;
 border-bottom:0
}
.nav-tabs.nav-justified>li {
 float:none
}
@media (min-width:768px) {
     .nav-tabs.nav-justified>li {
      display:table-cell;
      width:1%
     }
     .nav-tabs.nav-justified>li>a {
      margin-bottom:0
     }
}
.nav-pills>li {
 float:left
}
.nav-justified>li,.nav-stacked>li {
 float:none
}
.nav-pills>li>a {
 border-bottom:3px solid transparent;
 color:#999
}
.nav-pills>li.active>a,.nav-pills>li.active>a:focus,.nav-pills>li.active>a:hover,.nav-pills>li>a:hover {
 color:#2ecc71;
 border-color:#2ecc71
}
.nav-stacked>li>a {
 border-bottom:0;
 border-left:3px solid transparent
}
.nav-justified {
 width:100%;
 border-bottom:0
}
@media (min-width:768px) {
     .nav-justified>li {
      display:table-cell;
      width:1%
     }
     .nav-justified>li>a {
      margin-bottom:0
     }
}
.tab-content>.tab-pane {
 display:none;
 visibility:hidden;
 padding:2em;
 border:1px solid #e6e6e6;
 margin-top:-1px
}
.tab-content>.active {
 display:block;
 visibility:visible
}
.nav-tabs .dropdown-menu {
 margin-top:-1px;
 -webkit-border-top-right-radius:0;
 -moz-border-radius-topright:0;
 border-top-right-radius:0;
 -webkit-border-top-left-radius:0;
 -moz-border-radius-topleft:0;
 border-top-left-radius:0
}
.navbar-default .container-fluid{
    padding:0 !important;
}