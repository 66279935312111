.payment-getout-wrapper{
    margin:0;
    padding:40px 0;
    width:100%;
    
    .cart-bg{
        margin:0 0 15px 0;
        padding:10px;
        width:100%;
        float:left;
        border:1px solid @color-border;
        .rounded-corners (@radius: 6px);
        
        @media(max-width:640px){
            padding:15px;
        }
        
        .pet-img-bg{
            margin:0 0 0;
            padding:10px;
            width:12%;
            float:left;
            border:1px solid @color-border;
            .rounded-corners (@radius: 4px);
            height:110px;
            text-align:center;
            @media(max-width:991px){
                width:21%;
            }
            @media(max-width:640px){
                width:100%;
                height:140px;
                margin-bottom:10px;
            }
            
            img{
                max-width:100%;
                max-height:100%;
            }
        }
        .pet-content-bg{
            margin:30px 0;
            padding:0 1%;
            width:22%;
            float:left;
            border-right:1px solid @color-border;
            height:50px;
            display:table;
            text-align:center;
            @media(max-width:991px){
                width:39%;
                margin:5px 0;
                text-align: center !important;
            }
            @media(max-width:640px){
                width:100%;
                margin:0;
                text-align: left !important;
                border:0;
            }
                        
            p{
                margin:0 auto;
                display:table-cell;
                vertical-align:middle;
            }
            
        }
        .cart-plan{
            @media(max-width:991px){
               border:0;
            }
            p{
                display: inline-block;
            }
            strong{
                float: left;
                margin: 12px 10px 0 0;
            }
            i{
                font-size:36px;
                color:@color-blue;
                float: left;
                margin: 6px 0 0 0;
            }
            span{
                font-family: 'sf_pro_displaymedium';
                float: left;
                margin: 12px 0 0 10px;
                color:@color-blue;
            }
        }
    }
    .cart-payment-details{
        margin:0 0 15px 0;
        padding:18px 20px;
        width:100%;
        float:left;
        background:#fbfbfb;
        border:1px solid @color-border;
        font-family: 'sf_pro_displayregular';
        .rounded-corners (@radius: 6px);
        min-height:65px;
        p{
            float:right;
            margin:0 0 0 15px;
            font-family: 'sf_pro_displaysemibold';
            span{
                color:#b0afaf;
            }
        }
        .vet-points{
            margin:0 auto;
            padding:3px 10px 3px 20px;
            text-align:center;
            border:1px solid @color-border;
            background:#ffffff;
            display:inline-block;
            width:auto;
            color:@color-yellow;
            
            @media(max-width:575px){
                margin:5px auto;
            }
            
            i{
                font-size:26px;
                float:left;
                margin:3px 10px 0 0;
            }
            .custom-checkbox{
                margin:-3px 0 0 50px;
            }
            h5{
                color:#282828;
            }
        }
    }
    
    .total-amount {
    	 clear: both;
    	 padding: 20px 20px 0 20px;
    	 border-top: 1px solid @color-border;
    	 margin: 10px -20px -5px -20px;
    	 h5 {
            font-size: 20px;
            font-family: 'sf_pro_displaybold';
        }
    }
    .total-discount {
    	 clear: both;
    	 padding: 15px 20px 10px 20px;
    	 border-top: 1px solid @color-border;
    	 margin: 10px -20px -5px -20px;
    	 overflow: hidden;
    }
}
.emi-availabel {
	border-top: 1px solid @color-border;
	padding: 15px 0;
	margin-top: 20px;
	
	i {
		color:@color-green;
		font-size: 24px;
        float: left;
        margin: 0px 11px 0 0;
	}
	a {
		margin-left: 10px;
		text-decoration: underline;
		color:#0d7ef1;
        font-family: 'sf_pro_displayregular';

	}
}