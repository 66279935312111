.existing-user-wrapper{
    margin:0;
    padding:30px 0;
    width:100%;
    a{
        color:@color-green;
        font-size:35px;
    }
    @media(max-width:767px){
        .card{
            margin:0;
            flex: -moz-available;
            max-width: 100%;
            width: 100%;
            border:0 !important;
            .card-body{
                border:1px solid @color-border;
                border-top:0;
            }
        }
        .card-group{
            border-top:1px solid @color-border;
        }
    }
}
.checkbox-agree{
    float: right;
    padding-left: 0;
    .custom-control-label {
        padding: 3px 10px 0 0px;
    }
    .custom-control-label::before {
        right: 0;
        left: 10px;
        position: relative;
        float: right;
        top: 0;
    }
    .custom-control-label::after {
        top: 9px;
        right: 0;
        left: inherit;
    }
}
.deselect-section{
    opacity:0.2;
}
.selected-pet-details-bg{
    border-bottom:0;
    .card-body{
        position: relative;
        height: 98px;   
        @media(max-width:991px){
            height: 120px;       
        }
        @media(max-width:767px){
            height: auto;       
        }
    }
        
    .selected-pet-details{
        margin:15px 0 0 0;
        padding:15px;
        width:100%;
        height:135px;
        float:left;
        background:@color-gray;
        text-align:center;
        position: absolute;
        bottom:0;
        @media(max-width:767px){
            position: static;
        }
        img{
            max-width:100%;
            max-height:100%;
        }
    }
}

 
.bg-light-gray .form-control, .bg-light-gray .card {
    background-color: transparent;
}