@color-black:#494949;
@color-white:#ffffff;
@color-orange:#f06a39;
@color-brown:#c2733b;
@color-green:#20bf6b;
@color-yellow:#f39c12;
@color-gray:#fafafa;
@color-red:#e74c3c;
@color-blue:#59c0d8;
@color-border:#dddddd;



.color-gray{
    color:@color-gray !important;
}
.bg-gray{
    background:@color-gray !important;
}
.bg-orange{
    background:@color-orange !important;
}
.bg-brown{
    background:@color-brown !important;
}
.bg-yellow{
    background-color:@color-yellow !important;
}
.color-black{
    color:@color-black !important;
}
.color-white{
    color:@color-white !important;
}
.color-brown{
    color:@color-brown !important;
}
.color-orange{
    color:@color-orange !important;
}
.color-yellow{
    color:@color-yellow !important;
}
.color-red{
    color:@color-red !important;
}
.color-blue{
    color:#59c0d8 !important;
}
.color-green{
    color:@color-green !important;
}
.bg-dark-gray{
    color:#8c8c8c !important;
}
.bg-light-gray{
    background:#fcfcfc !important;
}