body{
    margin:0;
    padding:0;
    width:100%;
    color:@color-black;
    background:@color-white;
    overflow-x: hidden;
    font-family: 'sf_pro_displaylight';
    font-size:17px;
}
a {
    color: @color-orange;
    text-decoration:none;
}
a:hover, a:active, select, select:active, select:focus{
    outline: 0;
    text-decoration:none;
}
a:focus{
    outline: 0;
    text-decoration:none;
    border:0;
}
:focus {
    outline: 0;
}
.btn.focus, .btn:focus {
	outline: 0;
	box-shadow:none;
}
button:focus, button:active{
    outline: none;
    text-decoration:none;
}
:focus {
    outline: 0;
}
ul, ol {
    list-style: none;
}
img{
    max-width:100%;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea {
-webkit-appearance: none;
-moz-appearance: none; /* mobile firefox too! */
}


/*FONT STYLE*/
@font-face {
    font-family: 'sf_pro_displaybold';
    src: url('fonts/sf-pro-display-bold.woff2') format('woff2'),
         url('fonts/sf-pro-display-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_pro_displaylight';
    src: url('fonts/sf-pro-display-light.woff2') format('woff2'),
         url('fonts/sf-pro-display-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_pro_displaymedium';
    src: url('fonts/sf-pro-display-medium.woff2') format('woff2'),
         url('fonts/sf-pro-display-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_pro_displayregular';
    src: url('fonts/sf-pro-display-regular.woff2') format('woff2'),
         url('fonts/sf-pro-display-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_pro_displaysemibold';
    src: url('fonts/sf-pro-display-semibold.woff2') format('woff2'),
         url('fonts/sf-pro-display-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_pro_displayultralight';
    src: url('fonts/sf-pro-display-ultralight.woff2') format('woff2'),
         url('fonts/sf-pro-display-ultralight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



/*----Icon Font Face---------*/
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?q9f9k2');
  src:  url('fonts/icomoon.eot?q9f9k2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?q9f9k2') format('truetype'),
    url('fonts/icomoon.woff?q9f9k2') format('woff'),
    url('fonts/icomoon.svg?q9f9k2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-brochure:before {
  content: "\e928";
}
.icon-info:before {
  content: "\e926";
}
.icon-referral-code:before {
  content: "\e927";
}
.icon-user:before {
  content: "\e925";
}
.icon-twitter:before {
  content: "\e920";
}
.icon-pinterest:before {
  content: "\e921";
}
.icon-linkedin:before {
  content: "\e922";
}
.icon-instagram:before {
  content: "\e923";
}
.icon-facebook:before {
  content: "\e924";
}
.icon-user-details:before {
  content: "\e90f";
}
.icon-pet-details:before {
  content: "\e915";
}
.icon-summary:before {
  content: "\e916";
}
.icon-payment-gateway:before {
  content: "\e917";
}
.icon-radio-selected:before {
  content: "\e918";
}
.icon-add:before {
  content: "\e919";
}
.icon-dog-disease:before {
  content: "\e91a";
}
.icon-calendar:before {
  content: "\e91b";
}
.icon-browse:before {
  content: "\e91c";
}
.icon-delete:before {
  content: "\e91d";
}
.icon-points02:before {
  content: "\e91e";
}
.icon-view:before {
  content: "\e91f";
}
.icon-accidental-injuries:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-down01:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-back:before {
  content: "\e904";
}
.icon-checked:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-major-illness:before {
  content: "\e907";
}
.icon-major-soft:before {
  content: "\e908";
}
.icon-menu:before {
  content: "\e909";
}
.icon-next:before {
  content: "\e90a";
}
.icon-points:before {
  content: "\e90b";
}
.icon-ribbon:before {
  content: "\e90c";
}
.icon-save:before {
  content: "\e90d";
}
.icon-third-party:before {
  content: "\e90e";
}
.icon-plan-features01:before {
  content: "\e910";
}
.icon-plan-features02:before {
  content: "\e911";
}
.icon-plan-features03:before {
  content: "\e912";
}
.icon-plan-features04:before {
  content: "\e913";
}
.icon-plan-features05:before {
  content: "\e914";
}





.icon-twitter{
    font-size: 13px;
}


/*--Common Default Classes--*/
h1{
    margin:0;
    padding:0;
    font-size:70px;
    color:@color-black;
    line-height: 70px;
    @media(max-width:1100px){
        font-size:58px;    
        line-height: 58px;
    }
    @media(max-width:991px){
        font-size:40px;    
        line-height: 40px;
    }
    @media(max-width:570px){
        font-size:24px;    
        line-height: 24px;
    }
}
h2{
    margin:0 0 15px 0;
    padding:0;
    font-size:30px;
    color:@color-black;
    line-height: 30px;
    font-family: 'sf_pro_displaymedium';
}
h3{
    margin:0 0 25px 0;
    padding:0;
    font-size:50px;
    color:@color-black;
    line-height: 55px;
    letter-spacing: 2px;
    font-family: 'sf_pro_displayultralight';
    
    @media(max-width:767px){
        font-size:40px;    
        line-height: 42px;
    }
}
h5{
    font-size:20px;
    font-family: 'sf_pro_displaymedium';
}

.overflow{
    overflow:hidden;
}
/*------------Navigation CSS--------------*/
.navbar{
    /*position:fixed;*/
    width:100%;
    z-index: 99;
    top: 0;
    background:@color-white;
    
    /*@media(max-width:767px){
        position:absolute;
    }*/
    
    a{
        color:#766142;
        font-size: 14px;
        
        &:hover{
            color:@color-black;
        }
    }
    .active{
        color:@color-black;
        font-family: 'sf_pro_displaybold';
    }
    .nav-border{
        margin:0;
        border-bottom:1px solid #e8e8e8;
        width:100%;
    }
    .container{
        flex-wrap: wrap !important;
    }
}
/*.affix{
    transition: 0.8s ease;
    .navbar-brand{
        padding:10px 0;  
        transition: 0.3s ease;
    }
}
@media(max-width:991px){
    .navbar-nav {
        padding: 10px 0;
        border-top:1px solid #e8e8e8;
        background: rgba(255,255,255,0.9);
    }
}*/







/*--Common Classes--*/
.container{
    margin: 0 auto;
    padding: 0 20px;
    max-width:100%;
    position: relative;
    @media(min-width:1500px){
        max-width:1470px;
        padding: 0 15px;
    }
}
.mt-content{
    margin-top:125px !important;
    @media(max-width:1500px){
       margin-top:110px !important;
    }
    @media(max-width:991px){
       margin-top:75px !important;
    }
}

.rounded-corners (@radius: 6px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    -ms-border-radius: @radius;
    border-radius: @radius;     
}

/*--custom-checkbox--|--custom-radio ---*/
		
.custom-control-input:focus~.custom-control-label::before
{
  box-shadow:none;
}
.custom-control-label::before {
	width: 26px;
	height: 26px;
	background-color: #ffffff;
	border: 2px solid #eeeeee;
}
.custom-control-label::after {
	width: 26px;
	height: 26px;
	top: 9px;
	left: -24px;
	text-align: center;
	line-height: 17px;
}
.custom-control-label {
	padding: 3px 0 0 15px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background: #ffffff;
	color:#67af49;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
	content: "\e905";
	font-family: 'icomoon' !important;
	color:#67af49;
	font-size:10px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background: #ffffff;
	color:#67af49;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
	content: "\e918";
	font-family: 'icomoon' !important;
	color:#67af49;
	font-size:11px
}


/*--custom-select--*/

.custom-select:focus {
	border: 1px solid #ced4da;
	outline: 0;
	box-shadow:none !important;
}
.custom-select {
	display: inline-block;
	width: 100%;
	height: 38px;
	padding: 0 15px;
	font-size: 17px;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	background: #fff url('../img/arrow-down.png') no-repeat;
	background-position: right 14px;
	border: 1px solid #ced4da;
	.rounded-corners (@radius: 6px);
}

.custom-select-line {
	position:relative;
}
.custom-select-line:after {
	position:absolute;
	top:0;
	right:45px;
	border-left:1px solid #ced4da;
	height:38px;
	content:'';
}
.custom-bg .custom-select {
	height: 48px;
	color: #ffffff;
	background: @color-orange url('../img/arrow-down.svg') no-repeat;
	background-position: right 20px;
	background-size: 32px 11px;
	border: 0;
	font-family: 'sf_pro_displaysemibold';
	font-size:22px;
}
.custom-bg{
	position:relative;
}
.custom-bg:after {
    content:'';
	position:absolute;
	top:0;
	right:45px;
	border-left:1px solid rgba(255,255,255,0.4);
	height:48px;
    color: #fff;
}
.custom-bg .custom-select option {
	background:@color-orange;
	color:@color-white;
	font-size:18px;
	border:0;
	font-family: 'sf_pro_displayregular';
}
.custom-bg .custom-select.bg-yellow option {
	background:@color-yellow;
	color:@color-white;
	font-size:18px;
	border:0;
	font-family: 'sf_pro_displayregular';
}

.custom-bgpet .custom-select {
	margin: 0;
	border: 0;
	padding: 0 52px 0 8px;
	font-size:18px;
	height: auto;
	margin: 0;
	background: url('../img/arrow-downgreen.png') no-repeat;
	background-position: right 24px;
	background-size: 32px 11px;
	height: 54px;
	@media(max-width:768px){
		font-size:16px;
	}	
	@media(max-width:450px){
        height: 40px;
        background-position: right 15px;
	}
}

.custom-bgpet{
	position:relative;
	@media(max-width:450px){
        border-top:0px solid @color-border;
        padding-top:6px;
	}
	
}
.custom-bgpet:after {
    content:'';
	position:absolute;
	top:0px;
	right:46px;
	border-left:1px solid @color-border;
	height:54px;
    color: #fff;
    @media(max-width:450px){
        height: 55px;
	}
}
.disabled-select, .disabled-select.custom-bgpet:after {
        border-left:0;
    .custom-select{
        background:none;
    }
}
.disabled-select .custom-select{
        background:none;
}
.custom-bgpet .custom-select  option {
	background:#f0f0f0 !important;
	color:@color-black  !important;
	font-size:18px;
	border:0;
	font-family: 'sf_pro_displayregular';
}

.custom-bgpet .custom-select option {
	background:#ffffff;
	color:#282828;
}


/*---On Off Switch---*/

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 32px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  .rounded-corners (@radius: 34px);
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 3px;
  bottom: 2px;
  top:2px;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
  .rounded-corners (@radius: 50%);
}

input:checked + .slider {
  background-color: #08c068;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: 8px;
}


/*----Dropdown Menu---*/

.dropdown-bg .btn{
	background: #f15910;
	height: 60px;
	color: #fff;
	font-weight: bold;
	padding: 15px 5px 15px 15px;
	font-size: 18px;
}
.dropdown-bg .dropdown-menu.show {
	width: 100%;
}
.dropdown-bg .dropdown-toggle::after {
	border:0;
	background: #f15910 url('img/arrow-down01.png') no-repeat;
		background-position-x: 0%;
		background-position-y: 0%;
	background-position: 12px 26px;
	width: 40px;
	height: 60px;
	position: relative;
	top: 0;
	margin: -16px 0 0 20px;
	padding: 0;
	float: right;
	border-left:1px solid #fff;
}


/*--Internal Page Navigation--*/
.nav-int-bg{
    .navbar-collapse {
		position: absolute;
		top: 0px;
		right: -200px;
		padding: 15px;
		width: 200px;
		transition: all 0.3s ease-in;
		background:@color-brown;
		height:100vh;
		
	}
	.navbar-collapse.collapsing {
		transition: all 0.3s ease;
		right: 0%;
		top:0;
	}
	.navbar-collapse.show {
		right: 0;
	}
	.collapse:not(.show) {
		display: inherit;
	}
	.navbar-nav{
		position: absolute;
		top: 40px;
		border-top: 0;
        background: transparent;
	}
	.navbar-close{
		position: absolute;
		top: 0px;
		margin: 0;
        padding: 0 10px;
        font-family: 'sf_pro_displaybold';
        font-size: 20px;
        color:@color-white;
        background: #f6a704;
        left: 0;
        cursor: pointer;
	}
	.navbar-nav .nav-link {
        color: @color-white;
        padding: 8px 0 !important;
        &:hover{
            color:#f7d8c3;
        }
    }
}

.form-group{
    margin-bottom:38px;
    .form-control{
        height: 50px;
        font-size: 17px;  
    }
    .static{
        padding: 12px 15px 12px 15px;
        height:auto;
    }
}


.btn-custome{
    margin:0;
    padding:10px 30px;
    width:auto;
    display:inline-block;
    font-size:18px;
    font-family: 'sf_pro_displaysemibold';
    color:@color-white;
    background:@color-green;
    .rounded-corners (@radius: 4px);
    line-height: 22px;
    &:hover{
        color:@color-white;
        background:#48a539;
    }
    @media(max-width:991px){
        padding:8px 18px;
    }
}


/*----------ToolTip-----------------*/
.tooltip-inner {background-color: @color-red; color:@color-white;}
.bs-tooltip-top .arrow::before {
  border-top-color: @color-red;
}

#datepicker{
    background:url(../img/calendar.svg) no-repeat;
    background-position: 96% center;
    background-size: 22px auto;
}

/*---------FOOTER---------*/
.copyright{
    margin:0;
    padding:54px 0;
    width:100%;
    background:@color-orange;
    font-size:18px;
    font-family: 'sf_pro_displaymedium';
    color:@color-white;
    
    @media(max-width:991px){
        text-align:center;
    }
    @media(max-width:767px){
        padding:40px 0;
    }
    
    a{
        margin:0 0 0 50px;
        padding:2px;
        text-align:right;
        color:#fdbead;
        font-family: 'sf_pro_displaylight';
        font-size:16px;
        
        @media(max-width:1100px){
            margin:0 0 0 24px;
        }
        
        &:hover{
            color:@color-white;
        }
    }
    .col-auto{
        @media(max-width:991px){
            width:100%;
            text-align:center;
        }
        
    }
    .list-inline {
        padding-left: 0;
        list-style: none;
        float: right;
        margin:0;
        @media(max-width:991px){
            width:100%;
            float:inherit;
            margin-top: 15px;
        }
        li{
            float:left;
            @media(max-width:991px){
                float:inherit;
                display:inline-block;
            }
        }
    }
}






/*-----GET QUOTE Section-------*/
.get-quote-section {
  min-height: 330px;
  padding: 0 0 15px 0;
  position: fixed;
  top: 55%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: -300px;
  width: 300px;
  z-index: 999;
  color: #fff;
  .rounded-corners (@radius: 0 0 0 20px);

    .btn-get-quote {
        background: @color-green;
        width: auto;
        .rounded-corners (@radius: 4px 0px 0px 4px);
        position: absolute;
        left: -138px;
        top: 0%;
        display: block;
        color: #ffffff !important;
        padding: 15px;
        text-decoration: none;
        font-size: 20px;
        line-height: 22px;
        font-family: 'sf_pro_displaybold';
        cursor: pointer;
        @media(max-width:767px){
            top: 14%;
        }
    }
    #get-quote-form {
        margin: 0;
        padding: 20px;
        overflow: hidden;
        background:@color-green;
        
        h2{
            font-size:21px;
            color:@color-white;
            font-family: 'sf_pro_displaybold';
        }
        .form-control {
            height: 36px;
            padding: 15px 12px;
            font-size: 15px;
        }
        .form-group {
            margin-bottom: 20px;
        }
        .btn-submit {
            padding: 10px 15px;
            width: 140px;
            background:#f6a704;
            color:@color-white;
            font-family: 'sf_pro_displaymedium';
            font-size:16px;
        }
    }
    
}
/*-----/GET QUOTE Section-------*/

/*--Footer Navigation--*/
.footer-nav-wrapper{
    margin:0;
    padding:30px 0;
    width:100%;
    background:@color-yellow;
    a{
        font-family: 'sf_pro_displaymedium';
        font-size:15px;
        color:@color-white;
        letter-spacing: 2px;
        &:hover{
            color:#ffe5bc;
        }
        i{
            padding:0 4px;
            font-size:20px;
            vertical-align: middle;
            margin-top: -4px;
            display: inline-block;
        }
    }
}

/*---Register User--*/
.register-user-name-bg{
    margin:0;
    padding:0 10px 0 0;
    border-right:1px solid #dddddd;
    float:right;
    font-family: 'sf_pro_displayregular';
    
    @media(max-width:991px){
        padding:4px 0 0;
        border:0;
    }
    @media(max-width:767px){
        margin-top:6px;
        padding:0 10px 0 0;
        border-right:1px solid #dddddd;
    }
    
    i{
        font-size: 22px;
        margin: 0px 8px 0 0;
        float: left;
        color:@color-orange;
    }
    .dropdown-toggle::after {
        border: 0 !important;
    }
    a{
        cursor: pointer;
        &:hover{
            color:@color-orange !important;
        }
    }
    .dropdown-menu{
        margin:14px 0 0 0;
        width: 150px;
       .rounded-corners (@radius: 0x);
        .dropdown-item{
            margin:0;
            padding:4px 15px 4px 15px;
            color:#989898;
            &:hover{
                background:transparent;
                color:@color-black;
            }
        }
    }
}

/*--Script CSS--*/
#getQuoteDetails, #addPetDetails{
    display:none;    
}
.policyNumber, .clubRegistrationInput{
    display:none;      
}