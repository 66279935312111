.add-pet-wrapper{
    margin:0;
    padding:25px 0 30px;
    width:100%;
    border-bottom:1px solid @color-border;
    
    .btn-add-pet{
        margin:0;
        padding:0;
        float:right;
        color:@color-black;
        font-size:20px;
        font-family: 'sf_pro_displaysemibold';
        @media(max-width:767px){
            font-size:18px;
        }
        i{
            margin: 4px 0 0 15px;
            color:@color-green;
            font-size:25px;
            float:right;
        }
    }
    .btn-select-pet{
        margin:0 15px 0 0;
        padding:0 15px 0 0;
        float:right;
        color:@color-black;
        font-size:20px;
        font-family: 'sf_pro_displaysemibold';
        border-right:1px solid @color-border;
        @media(max-width:767px){
            font-size:18px;
            margin:0 10px 0 0;
            padding:0 10px 0 0;
        }
    }
    .add-pet-details-bg{
        margin:25px 0 0 0;
        padding:35px 0 0;
        width:100%;
        float:left;
        border-top:1px solid @color-border;
    }
    .add-pet-details{
        margin:0 6.66% 0 0;
        padding:0 0 40px;
        width:20%;
        float:left;
        
        @media(max-width:991px){
            margin:0 3%;
            width:44%;
        }
        @media(max-width:550px){
            margin:0%;
            width:100%;
        }
        
        .pet-img-bg{
            margin:0;
            padding:15px;
            width:100%;
            height:190px;
            position: relative;
            float:left;
            text-align:center;
            border:2px solid @color-orange;
            .rounded-corners (@radius: 8px);
            img{
                max-height:100%;
            }
            @media(max-width:1100px){
                height:150px;
            }
            @media(max-width:991px){
                height:190px;
            }
            @media(max-width:600px){
                height:170px;
            }
            
            .ribbon-type{
                position:absolute;
                top:4px;
                left:4px;
                padding: 3px 7px 0px;
                .rounded-corners (@radius: 50%);
                background:@color-white;
                font-size: 24px;
            }
            .incomplete-details{
                position:absolute;
                top:4px;
                right:4px;
                font-size: 20px;
                padding: 3px 7px 0px;
                .rounded-corners (@radius: 50%);
                background:@color-white;
                color:@color-red;
            }
        }
        .border-green{
            border:2px solid @color-green;
        }
        .pet-name{
            margin:16px 0 8px;
            padding:0;
            font-size:20px;
            width:100%;
            float: left;
            font-family: 'sf_pro_displaysemibold';
        }
        .pet-details{
            margin:0 0 8px 0;
            padding:0;
            width:100%;
            float: left;
            font-size:18px;
        }
        .pet-details-action{
            margin:6px 0 0 0;
            padding:15px 0;
            font-size:32px;
            width:100%;
            float:left;
            border-top:1px solid @color-border;
            border-bottom:1px solid @color-border;
            
            .btn-edit{
                color:@color-green;
                margin: -4px 0 -11px 0;
                float: left;
            }
            
            .btn-custome{
                font-family: 'sf_pro_displaymedium';
                font-size:17px;
                padding:8px 20px;
                float:right;
            }
        }
            
    }
    .delete-pet-icon{
        display:none;
        float:right;
        font-size:20px;
        color:@color-red;
    }
    .delete-pet{
        .delete-pet-icon{
            display:block;
        }  
        .btn-custome{
            background:@color-black;
            opacity: 0.2;
        }
        .btn-edit i{
            color:@color-black;
            opacity: 0.2;
        }
    }
}
.medical-history-wrapper{
    margin:0;
    padding:45px 0;
    width:100%;
    
    @media(max-width:575px){
        .float-right{float:left !important;}
    }
}
.pet-diagnosed {
    padding: 25px;
    font-size:21px;
    font-family: 'sf_pro_displaybold';
    text-align:center;
}
.pet-diagnosed-yes {
    padding: 25px;
    font-size:21px;
    font-family: 'sf_pro_displayregular';
    text-align:center;
    color:@color-orange;
    display:none;
}



/*--------Section02-----------*/
.pet-details-quote{
    margin-top:0;
    border-top:0;
    
    .pet-quote-left{
        width:72%;
        float:left;
        
        @media(max-width:991px){
           width:63%;  
        }
        @media(max-width:767px){
           width:50%;  
        }
        @media(max-width:560px){
           width:100%;  
        }
        
        .add-pet-details {
            margin: 0 6% 0 0;
            width: 27%;
            
            @media(max-width:1100px){
                margin: 0 3% 0 0;
                width: 30%;    
            }
            @media(max-width:991px){
                margin: 0 8% 0 0;
                width: 42%;    
            }
            @media(max-width:767px){
                margin: 0 15% 0 0;
                width: 85%;    
            }
            @media(max-width:560px){
               margin: 0;
                width: 100%;
            }
            
            .pet-img-bg {
                border: 2px solid @color-green;
                @media(max-width:991px){
                height:150px;
                }
            }
            .pet-name {
                width: auto;
            }
            .btn-edit {
                color: @color-green;
                margin: 10px 0 0 0;
                float: right;
                font-size: 32px;
            }
            .pet-coverage {
                margin: 8px 0 0 0;
                padding: 13px 0;
                font-size: 17px;
                width: 100%;
                float: left;
                border-top: 1px solid @color-border;
                border-bottom: 1px solid @color-border;
                label{
                    margin:0;
                    padding:0;
                    margin-right:15px;
                    float:left;
                    font-family: 'sf_pro_displaysemibold';   
                    color:#494949;
                    @media(max-width:1100px){
                       margin-right:0px;   
                    }
                }
                span{
                    padding:0;
                    font-family: 'sf_pro_displaylight';
                    font-size: 18px;
                }
                .form-group{
                    margin:0;
                    float:right;
                    width:60%;
                }
                .custom-select {
                    padding: 0 5px;
                }
                
            }
        }
        
    }
    .pet-quote-right{
        margin:0;
        width:28%;
        float:left;
        background:#f3f3f3;
        border:1px solid @color-border;
        .rounded-corners (@radius: 6px);
        
        @media(max-width:991px){
           width:37%;  
        }
        @media(max-width:767px){
           width:50%;  
        }
        @media(max-width:560px){
           width:100%;  
        }
        
        .payment-details-bg{
            margin:0;
            padding:10px 20px 10px;
            width:100%;
            float:left;
            border-bottom:1px solid @color-border;
            
            .payment-details-row{
                margin:0 4%;
                padding:10px 0;
                width:92%;
                float:left;
                border-bottom:1px solid @color-border;
                font-family: 'sf_pro_displaysemibold';
                
                color:#494949;
                
                label{
                    margin:0;
                    padding: 0;
                    margin-right:15px;
                    float:left;
                }
                span{
                    padding:0;
                    float:right;
                    text-align:right;
                }
            }  
            .vet-coins-bg{
                margin:10px 0;
                padding:6px 15px 5px;
                width:100%;
                float: left;
                background:@color-white;
                .rounded-corners (@radius: 6px);
                
                
                .payment-details-row{
                    margin:0;
                    padding:6px 0;
                    width:100%;
                    border-bottom:0;
                    font-family: 'sf_pro_displayregular';
                    
                    label{
                        width:50%;
                        line-height: 22px;
                    }
                    .vet-coins{
                        padding-top: 4px;
                        font-size:15px;
                        color:@color-yellow;
                        i{
                            font-size:20px;
                            margin-right: 10px;
                            float: left;
                            margin-top: 0px;
                        }
                    }
                }
            }
            .pay-now-bg{
                margin:0;
                padding:10px 0 0 0;
                width:100%;
                float:left;
                text-align:center;
                .btn-custome {
                    padding: 14px 40px;
                }
            }
        }
        
        .emi-bg{
            margin:0;
            padding:10px 40px;
            width:100%;
            float:left;
            background:#f9f9f9;
            .rounded-corners (@radius:0 0 6px 6px);
            
            i{
                margin:2px 15px 0 0;
                font-size:20px;
                color:@color-green;
                float:left;
            }
            a{
                color:#0d7ef1;
                text-decoration:underline;
                font-family: 'sf_pro_displayregular';
                float:right;
            }
        }
        
        
    }
    
}

.pet-payment-info-wrapper {
    margin: 0;
    padding: 30px 0;
    width: 100%;
    border-bottom:1px solid @color-border;
    font-size:17px;
}
.semibold{
    font-family: 'sf_pro_displaysemibold';
    font-size:17px;
}
.diagnosed-condition-bg{
    margin-top:35px;
    margin-bottom:50px;
    border-top:1px solid @color-border;
    border-bottom:1px solid @color-border;
    
    
    .diagnosed-condition{
        margin:0;
        padding:15px 0;
        width:100%;
        float:left;
        
        border-right:1px solid @color-border;
        text-align:center;
        
        @media(max-width:991px){
            text-align:center !important;
        }
        @media(max-width:575px){
            border-right:0;
            min-height: auto;
        }
        
        .switch{
            margin:0 0 0 8%;
            @media(max-width:575px){
                float:right;
            }
        }
        p{
            width: auto;
            display: block;
            font-family: 'sf_pro_displaysemibold';
            
            @media(max-width:575px){
                width: 46%;
                float:left;
                text-align: left;
            }
        }
    }
    .blood-related{
        @media(max-width:991px){
            border-top:1px solid @color-border;
        }
        @media(max-width:575px){
            border-bottom:1px solid @color-border;
        }
    }
    .liver-related{
        @media(max-width:991px){
            border-bottom:1px solid @color-border;
            border-right:0;
        }
        @media(max-width:575px){
            border-bottom:0;
            border-top:1px solid @color-border;
        }
    }
}
.medical-conditions{
    textarea.form-control{
        height:100px !important;
        resize:none;
    }
}
.vet-details-wrapper{
    margin:0;
    padding:0;
    width:100%;
    background:#fefefe;
    position:relative;
    
    .vet-details-bg-right{
        margin:0;
        padding:0;
        width:30%;
        position:absolute;
        right:0;
        top:0;
        bottom:0;
        background:#f4f4f4;
        @media(max-width:1199px){
            display:none;
        }
    }
    .vet-details-right-content{
        margin:0;
        padding:75px 0px 40px 40px;
        width:100%;
        position:relative;
        z-index:1;
        background:#f4f4f4;
        
        @media(max-width:1199px){
            padding: 40px 15px 30px 15px;
        }        
        
        textarea.form-control{
            height:140px !important;
            resize:none;
        }
    }
    .vet-details-bg-left{
        margin:0;
        padding:70px 0 20px;
        width:100%;
        
        .custom-select{
            height:50px;
            background-position: right 19px;
        }
        .custom-select-line::after {
            height: 50px;
        }        
        .pet-dob-bg{
            margin:0;
            padding:8px 15px;
            width:100%;
            height:50px;
            border:1px solid @color-border;
            .rounded-corners (@radius: 4px);
            position:relative;
            
            @media(max-width:767px){
                height: auto;
                overflow: hidden;
            }
            
            .custom-select{
                padding: 0 10px;
                height:32px;
                background-position: right 10px;
                .rounded-corners (@radius: 2px);
                @media(max-width:991px){
                    padding: 0 3px;
                    font-size: 15px;
                    background-size: 20px;
                    background-position: right 12px;
                }
                @media(max-width:767px){
                    font-size: 15px;
                }
                
            }
            .custom-select-line::after {
                height: 32px;
                @media(max-width:991px){
                    right: 28px;
                }
            }
            
            span{
                margin:3px 0 0 0;
                width:12%;
                float:left;
            }
            .date{
                width:22%;
                float:left;
            }
            .month{
                padding:0 8px;
                width:38%;
                float:left;
            }
            .year{
                width:28%;
                float:left;
            }
        }
        .pet-dob-note{
            margin:0;
            padding:10px 15px;
            border:1px solid @color-border;
            .rounded-corners (@radius: 4px);
            position:absolute;
            text-align:center;
            font-size:16px;
            background:#fbfbfb;
            color:@color-orange;
            top: -62px;
            left: 15px;
            right: 15px;
            @media(max-width:767px){
                margin: 0;
                padding: 6px 10px;
                position: relative;
                float: left;
                top: -7px;
                left: 0;
            }
        } 
        .pet-dob-note:after, .pet-dob-note:before {
        	top: 100%;
        	left: 50%;
        	border: solid transparent;
        	content: " ";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        }
        .pet-dob-note:after {
        	border-color: rgba(251, 251, 251, 0);
        	border-top-color: #fbfbfb;
        	border-width: 7px;
        	margin-left: -7px;
        }
        .pet-dob-note:before {
        	border-color: rgba(221, 221, 221, 0);
        	border-top-color: #dddddd;
        	border-width: 8px;
        	margin-left: -8px;
        }
        
        
    }
    
    .avatar-upload {
        position: relative;
        width: 100%;
        margin: 0;
        font-family: 'sf_pro_displaymedium';
        float: left;
        .avatar-edit {
            z-index: 1;
            float:left;
            padding:10px 15px;
            margin:0 0 0 15px;
            border:1px solid @color-border;
            width:auto;
            
            input {
                display: none;
                + label {
                    display: inline-block;
                    padding:6px 15px; 
                    margin:0;
                    width: auto;
                    background: @color-green;
                    cursor: pointer;
                   .rounded-corners (@radius: 4px);
                    color:@color-white;
                    
                    &:hover {
                        background: #48a539;
                    }
                    i{
                        font-size:22px;
                        margin:2px 10px 0 0;
                        float: left;
                    }
                    
                }
            }
        }
        .avatar-preview {
            padding:10px;
            width: 140px;
            height: 130px;
            float:left;
            border:1px solid @color-border;
            > div {
                width: 100%;
                height: 100%;
                border:1px solid @color-border;
                background-size:cover;
                background-position: center;
                
            }
        }
        .browse-action{
            position:absolute;
            bottom:-4px;
            left:160px;
            font-size:22px;
            a{
                color:@color-orange;
                margin:0 10px 0 0;
            }
            a.img-edit{
                color:@color-green;
                font-size:20px;
            }
        }
    }
    .club-registration-left{
        float:left;
        @media(max-width:991px){
            float:left;
            width:100%;
        }
    }
    .club-registration-right{
        float:right;
        @media(max-width:991px){
            float:left;
            margin:15px 0;
            width:100%;
        }
    }
}

.wrapper-dog-details{
	.pet-details-select{
	    margin:0;
	    padding:10px 5px 10px 15px;
	    width:100%;
	    background:#f0f0f0;
	    font-size:20px;
	    border-left:1px solid @color-border;
	    border-bottom:1px solid @color-border;
	    height:90px;
	    font-family: 'sf_pro_displayregular';
	    display:table;
	    text-align:left;
	    
	    @media(min-width:1500px){
	        padding:10px 5px 10px 30px;
	    }
	    @media(max-width:1100px){
	        font-size:16px;
	    }
	    @media(max-width:767px){
	        height:60px;
	    }
	    
	    strong{
	        font-family: 'sf_pro_displaymedium';
	        margin-right:12px;
	        font-weight:normal;
	    }
	    span{
	        color:#494949;
	    }
	    p{
	        display:table-cell;
	        vertical-align:middle;
	        line-height: 22px;
	    }
	    
	}
	.dog-type-bg{
        background:#e5e5e5;
    }
}

.pet-label {
	float: left;
	width: 30%;
	
	@media(max-width:1024px){
        width: 18%;
    }
    @media(max-width:768px){
        width: 100%;
    }
	
}
.pet-selectarea {
	float: left;
	width: 70%;
	
	@media(max-width:1024px){
        width: 82%;
    }
    @media(max-width:768px){
        width: 100%;
    }
	
}