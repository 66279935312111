.wrapper-banner{
    margin:0 0 0 0;
    padding:0;
    width:100%;
    
     @media(max-width:600px){
        padding:10px 0 0 0;
    }
    
    h1{
        span{
            font-family: 'sf_pro_displayultralight';
            color:#8d684c;
        }
    }
    img{
        margin:35px -50px 0 0;
        
        @media(max-width:1500px){
            margin:20px 0 0 0;  
            height:500px;
        }
        @media(max-width:1100px){
            height:400px;
        }
        @media(max-width:991px){
            height:auto;
            
        }
    }
    a{
        margin: 17% 0 14%;
        float:left;
        color:@color-black;
        i{
            font-size: 30px;
            float: left;
            margin: -3px 12px 0 0;
            color:@color-red;
        }
        
        &:hover{
            color:@color-brown;
            i{
                color:@color-brown;
            }
        }
        @media(max-width:600px){
            line-height: 14px;
            font-size: 13px;
            margin: 14% 0 9%;
        }
        
    }
}
.wrapper-pawtect-Plan{
    padding:95px 0 75px 0;
    background:@color-brown;
    
    @media(max-width:767px){
        padding:50px 0 75px 0;
        h2 {
            margin: 0 0 4px 0;
            font-size: 22px;
        }
        h3 {
            font-size: 22px;
            line-height: 26px;
            margin: 0 0 15px 0;
        }
    }
        
    h2, h3, p{
        color:@color-white;
        position:relative;
        z-index:1;
    }
    p{
        
        @media(max-width:1199px){
            margin-bottom:190px;
        }
        @media(max-width:767px){
            margin-bottom:98px;
        }
        @media(max-width:575px){
            margin-bottom:40px;
        }
    }
    
    .img-pawtect-plan{
        position:absolute;
        bottom: -205px;
        right: -20px;
        @media(max-width:1500px){
            bottom: -194px;
            right: 0px;
            width: 800px;
        }
        @media(max-width:991px){
            bottom: -186px;
            width: 750px;
        }
        @media(max-width:767px){
            bottom: -154px;
            width: 530px;
        }
        @media(max-width:575px){
            bottom: -126px;
            width: 345px;
        }
        @media(max-width:350px){
            bottom: -123px;
        }
        
    }
}
.wrapper-your-pets{
    padding:125px 0 40px 0;
    color:#4c4c4c;
    @media(max-width:767px){
        padding:75px 0 40px 0;
    }
    
    h2{
        margin: 0 0 6px 0;
    }
    h3{
        font-size:30px;
        line-height:30px;
        letter-spacing: 1px;
        margin: 0 0 30px 0;
    }
    span{
        font-size:11px;
        color:#b1b1b1;
    }
    .divider{
        margin:30px 0 45px 0;
        padding:0;
        width:100%;
        border:1px solid @color-gray;
    }
}

.wrapper-plan-features{
    padding:60px 0;
    background:@color-gray;
    
    @media(max-width:767px){
        padding:20px 0;    
    }
    
   i{
        margin:0 0 40px 0;
        padding:0 0 0 10%;
        height:36px;
        width:100%;
        float:left;
        color:@color-orange;
        font-size:26px;
        @media(max-width:767px){
           margin:0 0 10px 0; 
        }
    }
    p{
        margin:0;
        padding:0 10%;
        width:100%;
        min-height:80px;
        float:left;
        font-size:22px;
        color:@color-orange;
        font-family: 'sf_pro_displaymedium';
        border-left:1px solid #e3e3e3;
        line-height: 24px;
        
        @media(max-width:1100px){
            min-height:95px;
        }
        @media(max-width:991px){
            min-height:120px;
        }
        @media(max-width:767px){
            border-left:0;
        }
        @media(max-width:575px){
            min-height:auto;
        }
    }
    @media(max-width:767px){
        .col{
            flex-basis: inherit;
            max-width:33.33%;
            margin-bottom:40px;
        }
    }
    @media(max-width:575px){
        .col{
            max-width:50%;
        }
    }
    
}