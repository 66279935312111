.navbar {
    padding:0;
    .container{
        flex-wrap: wrap !important;
        @media(max-width:991px){
           padding:0 15px;
        }
    }
}
.navbar-brand {
   padding:0px 0 10px 0;
   @media(max-width:1500px){
        width:150px;
    }
   @media(max-width:991px){
        padding:20px 0 10px 0px;
    }
}
.navbar-nav .nav-link {
    padding:0 40px !important;
    letter-spacing: 2px;
    @media(max-width:1200px){
        padding:0 20px !important;
    }
    @media(max-width:991px){
        padding:3px 0px !important;
    }
}
.navbar-nav li.nav-item:last-child .nav-link {
    padding-right:0 !important;
}
.navbar-toggler-icon {
    background: url("../img/menu-icon.svg") no-repeat;
    width: 43px;
    height: 28px;
}

.form-group {
    margin-bottom: 38px;
}
.form-control {
   border:1px solid #d6d6d6;
}

.form-control:focus {
    border:1px solid @color-border;
    box-shadow: none;
}
.custom-control {
    display: inline-block;
}
textarea.form-control{
    text-align:left;
}
.invalid-feedback {
    margin-top: 8px;
    line-height:14px;
}
.modal-backdrop {
    z-index: 9991;
}
.modal {
    z-index: 9992;
}
.modal-backdrop.show {
    opacity: .3;
}
.modal-header {
    background:@color-orange;
    display: inline-block;
    color:@color-white;
    font-size:45px;
    text-align:center;
     .close {
        color: #fff;
    }
}

.modal-footer {
    display: inline-block;
    align-items: center;
    padding: 0 0 40px 0;
    text-align: center;
    border-top:0;
    .btn{
        margin:0 8px;
        padding:10px;
        width:95px;
        border:1px solid @color-border;
        color:#494949;
        font-size: 18px;
    }
}
